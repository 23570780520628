import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RecursosList, Sidebar } from '@core/interfaces/sidebar.interface'
import { UserAdminRol } from '@core/interfaces/user-admin.interface'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable, take, tap } from 'rxjs'

/**
 * Servicio que contiene la lógica del sidebar
 */
@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	isSideBarExpanded: boolean = false
	isMobileMenuOpen: boolean = false
	isMobileMenuDialogOpen: boolean = false

	urlServer = environment.urlServer
	currentRole: BehaviorSubject<UserAdminRol> = new BehaviorSubject<UserAdminRol>({} as any)
	currentUser: BehaviorSubject<any> = new BehaviorSubject<any>({} as any)

	constructor(private readonly httpClient: HttpClient) {}

	getSidebar(id: number): Observable<Sidebar> {
		return this.httpClient.get<Sidebar>(`${this.urlServer}/admin/api/perfil/buscar?rolId=${id}`).pipe(
      take(1),
			tap((data) => {
				data.listaRecursos = this.sortByOrder(data.listaRecursos)
				data.listaRecursos = this.filterByStatus(data.listaRecursos)
				data.listaRecursos.forEach((item) => {
					item.hidden = false
					item.listHijosN2 = this.sortByOrder(item.listHijosN2)
					item.listHijosN2 = this.filterByStatus(item.listHijosN2)
					item.listHijosN2.forEach((sub) => {
						sub.hidden = false
					})
				})
			}),
			tap((data) => {
				localStorage.setItem('routeLinks', JSON.stringify(data.listaRecursos))
				const userData = {
					nombre: data.nombres + ' ' + data.apellidos,
					email: data.email,
					foto: data.foto,
				}
				localStorage.setItem('userData', JSON.stringify(userData))
			})
		)
	}

	filterByStatus(array: any[]) {
		return array?.filter((sub) => sub.status !== false)
	}

	sortByOrder(array: any[]) {
		return array ? [...array].sort((a, b) => a.orden - b.orden) : []
	}

	toggleDesktopSideBar(): void {
		this.isSideBarExpanded = !this.isSideBarExpanded
	}

	toggleMobileSideBar(): void {
		this.isMobileMenuOpen = !this.isMobileMenuOpen

		// Truco para ocultar el menú móvil con animación
		if (this.isMobileMenuDialogOpen) {
			// Si el menú móvil está abierto, se cierra después de 400ms
			setTimeout((): void => {
				this.isMobileMenuDialogOpen = !this.isMobileMenuDialogOpen
			}, 400)
		} else {
			this.isMobileMenuDialogOpen = !this.isMobileMenuDialogOpen
		}
	}

	setLocalActiveRole(selected: UserAdminRol) {
		localStorage.setItem('activeRol', JSON.stringify(selected))
	}

	getLocalActiveRole(): UserAdminRol | null {
		const activeRol = localStorage.getItem('activeRol') ?? ''
		if (activeRol) {
			return JSON.parse(activeRol)
		}

		return null
	}

	getLocalRoutes(): RecursosList[] | null {
		const routes = localStorage.getItem('routeLinks') ?? ''
		if (routes) {
			return JSON.parse(routes)
		}

		return null
	}
}
